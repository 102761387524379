.DateRangePickerInput,
.DateRangePickerInput__withBorder {
  border-radius: 8px!important;
  border: 1px solid #4E5526!important;
  height: 40px!important;
}

.DateInput, .DateInput_1, .DateInput__small, .DateInput__small_2 {
  border-radius: 8px!important;
  border: 2px solid #fff!important;
}

