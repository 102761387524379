.titulo-contratos {
  font-size: 10px;
  font-weight: bold;
  color: #000;
}

.filtros-contratos-pdf {
  font-size: 8px;
  font-weight: bold;
  color: #000;
}

.nome-filtro {
  font-weight: 600;
  font-size: 8px;
}

.cabecalho-contratos th {
  text-align: left;
  width: max-content;
  background-color: #008000;
  color: #fff;
  margin: 0;
}

.body-contratos td {
  text-align: left;
  width: max-content;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: normal;
  word-spacing: -1px;
  margin: 1px 0;
  padding: 0;
}

.tabela-contratos {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

/* .tabela-contratos table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
} */

.tabela-contratos th,
.tabela-contratos td {
  border: 1px solid black;
  padding: 6px 0px 6px 4px;
  text-align: left;
}

.div-pdf {
  font-family: Arial, Helvetica, sans-serif;
}